<!--
 * @Author: mulingyuer
 * @Date: 2021-04-08 18:31:45
 * @LastEditTime: 2021-04-13 15:07:54
 * @LastEditors: mulingyuer
 * @Description: 新增&编辑
 * @FilePath: \saas-admin-vue\src\modules\vote\views\add-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" size="medium" label-width="80px" class="small-form" @submit.native.prevent>
        <el-form-item label="标题：" prop="title">
          <el-input v-model="form.title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="投票类型：" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">文字</el-radio>
            <el-radio :label="1">图文</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选项内容：" prop="options">
<!--          <text-option v-if="form.type===0" v-model="form.options" />-->
<!--          <img-text-option v-else-if="form.type===1" v-model="form.options" />-->
          <el-button size="small" type="primary" @click="handleAdd" style="margin-bottom: 20px;">添加选项</el-button>
          <el-table :data="form.options" class="thead-light"
                    stripe style="width: 100%; max-width: 800px;"
                    id="sortTable" row-key="timestamp">
            <!-- 操作 -->
            <el-table-column label="操作" :width="120" fixed="right">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button type="text" size="small" @click="EditRow(scope.$index)">编辑</el-button>
                  <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
                </el-button-group>
              </template>
            </el-table-column>
            <!-- 拖拽排序 -->
            <el-table-column label="拖拽排序" :width="90">
              <span class="el-icon-sort my-handle cursor-pointer"/>
            </el-table-column>
            <template v-for="(th, index) in thead.filter(el=>form.type === 1?el.prop !== '' : el.prop !== 'avatar')">
              <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
                <template slot-scope="scope">
                  <list-image v-if="th.prop === 'avatar' && scope.row.avatar" :width="100" :src="scope.row.avatar" fit="cover"></list-image>
                  <span v-else-if="th.prop === 'type'">{{form.type === 0 ? '文字':'图文'}}</span>
                  <!-- 普通 -->
                  <span v-else>{{scope.row[th.prop] | placeholder}}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-form-item>
        <el-form-item label="选项序号：" prop="show_number">
          <el-radio-group v-model="form.show_number">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
          <div class="info"><i class="el-icon-info"></i>开启后将在选项里显示序号</div>
        </el-form-item>
        <el-form-item label="投票方式：" prop="more">
          <el-radio-group v-model="form.more">
            <el-radio :label="0">单选</el-radio>
            <el-radio :label="1">多选</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="投票时间：" prop="time">
          <DateTimePicker v-model="form.time" />
        </el-form-item>
        <el-form-item label="投票票数：" prop="limit_type" class="limit">
          <el-radio-group v-model="form.limit_type">
            <el-radio :label="0">每人每天可投<el-input v-model="form.daily_limit" :disabled="form.limit_type===1">
              </el-input>票</el-radio>
            <el-radio :label="1">每人可投<el-input v-model="form.total_limit" :disabled="form.limit_type===0">
              </el-input>票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="投票结果：" prop="is_open">
          <el-radio-group v-model="form.is_open">
            <el-radio :label="1">所有人可见</el-radio>
            <el-radio :label="0">投票后可见</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!-- 悬浮按钮 -->
      <fixed-action-bar>
        <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading"
          @click="onSave('ruleForm')">保存</el-button>
        <el-button size="medium" @click="onCancel">取消</el-button>
      </fixed-action-bar>
    </div>
    <option-create-dialog v-model="optionDialog.show" :type="form.type"
                          :form="optionDialog.data" :index="optionDialog.index"
                          @confirm="handleSaveOption"/>
  </div>
</template>
<script>
  // import TextOption from "../components/vote-list/TextOption";
  // import ImgTextOption from "../components/vote-list/ImgTextOption";
  import DateTimePicker from "@/base/components/Form/DateTimePicker";
  import FixedActionBar from "@/base/layout/FixedActionBar";
  import {voteEditDetail, voteSave} from "../api/vote-list";
  import ListImage from "@/base/components/List/ListImage";
  import OptionCreateDialog from "@/modules/vote/components/OptionCreateDialog";
  import Sortable from "@/modules/page-renovation/js/Sortable.min";

  export default {
  data() {
    return {
      form: {
        id: 0, //0为新增，其他id值为编辑
        title: "", //标题
        type: 0, //类型 0：文字 1：图文
        options: [], //选项
        more: 0, //投票方式 0:单选，1：多选
        show_number: 0, //是否显示选项序号
        time: [-1, -1], //开始时间 & 结束时间
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
        limit_type: 0, //投票限制方式：0每天可投次数限制，1限制总投票次数
        daily_limit: 1, //每天的投票次数
        total_limit: 1, //总的投票次数
        is_open: 1, //投票结果 0:投票后可见，1：所有人可见
      },
      rules: {},
      loading: false, //编辑的数据获取中
      saveLoading: false, //保存中
      editData: {}, //编辑时api获取的数据
      thead: [
        {label: '选项标题', prop: 'name', },
        {label: '图片', prop: 'avatar'},
        {label: '投票类型', prop: 'type'}
      ],
      optionDialog: {
        show: false,
        data: {},
        index: -1,
      }
    };
  },
  computed: {
    //投票数据的id
    voteId() {
      return this.$route.query.id;
    },
  },
  methods: {
    handleSaveOption(form) {
      console.log(form)
      if (this.optionDialog.index === -1) {
        this.form.options.push(form)
      } else {
        this.$set(this.form.options, this.optionDialog.index, form)
      }
    },
    handleAdd() {
      this.optionDialog.data = {
        name: '',
        avatar: '',
        timestamp: new Date().getTime()
      }
      this.optionDialog.index = -1
      this.optionDialog.show = true
    },
    EditRow(i) {
      let data = this.form.options[i]
      this.optionDialog.data = {
        name: data.name,
        avatar: data.avatar || '',
        timestamp: data.timestamp
      }
      this.optionDialog.index = i
      this.optionDialog.show = true
    },
    DeleteRow(i) {
      this.form.options.splice(i, 1)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = this.initSaveData();
          voteSave(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.$router.push({ name: "VoteList" });
              this.saveLoading = false;
              this.resetForm(formName);
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    //取消
    onCancel() {
      this.$router.push({ name: "VoteList" });
    },
    //获取编辑的数据
    getVoteEditDetail() {
      if (typeof this.voteId !== "undefined") {
        this.loading = true;
        const postData = { id: this.voteId };
        voteEditDetail(postData)
          .then((res) => {
            const { data } = res;
            // console.log(data);
            this.editData = data;
            //初始化表单数据
            this.initForm();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    //初始化表单数据
    initForm() {
      const keys = Object.keys(this.form);
      keys.forEach((key) => {
        if (key === "limit_type") {
          if (this.editData.is_daily_limit === 1) {
            this.form[key] = 0;
          } else if (this.editData.is_total_limit === 1) {
            this.form[key] = 1;
          }
        } else if (key === "time") {
          this.form[key] = [this.editData.start_time, this.editData.end_time];
        } else {
          this.form[key] = this.editData[key];
        }
      });
      this.form.options = this.form.options.map((el, i) => {
        return {
          ...el,
          timestamp: new Date().getTime() + i * i
        }
      })
      this.$nextTick(() => {
        this.drag()
      })
    },
    // 保存时数据转换
    initSaveData() {
      const newData = Object.assign({}, this.form);
      // 投票类型
      delete newData.limit_type;
      if (this.form.limit_type === 0) {
        newData.is_daily_limit = 1;
        newData.is_total_limit = 0;
      } else if (this.form.limit_type === 1) {
        newData.is_daily_limit = 0;
        newData.is_total_limit = 1;
      }
      //投票时间
      delete newData.time;
      newData.start_time = this.form.time[0];
      newData.end_time = this.form.time[1];

      /* 投票选项 */
      newData.options = this.form.options.map(el => {
        return {
          name: el.name,
          avatar: el.avatar
        }
      })
      return newData;
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        handle: ".my-handle",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = JSON.parse(JSON.stringify(this.form.options)); // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          this.$nextTick(() => {
            this.form.options = arr
          });
        },
      });
    },
  },
  created() {
    this.getVoteEditDetail();
  },
  //路由进入前
  beforeRouteEnter(to, from, next) {
    if (typeof to.query.id !== "undefined") {
      to.meta.title = "编辑";
    } else {
      to.meta.title = "新增";
    }
    next();
  },
  components: {
    OptionCreateDialog,
    ListImage,
    // TextOption,
    // ImgTextOption,
    DateTimePicker,
    FixedActionBar,
  },
};
</script>
<style lang="scss" scoped>
.add-edit {
  .content {
    padding: 20px 0;
    .limit {
      ::v-deep.el-radio-group {
        .el-input {
          margin-left: 10px;
          margin-right: 5px;
          max-width: 80px;
        }
      }
    }
  }
}
</style>
