<template>
  <el-dialog v-if="value" class="dialog-vertical" :title="dialogTitle" :visible="value" @close="close" width="500px">
    <el-form ref="optionForm" class="small-form" size="medium"
             :rules="rules" :model="form"
             label-position="right" label-width="85px"
             @submit.native.prevent>
      <el-form-item label="选项标题：" prop="name">
        <el-input type="text" v-model="form.name" v-focus placeholder="请填写" />
      </el-form-item>
      <el-form-item v-if="type === 1" label="上传图片：" prop="avatar">
        <single-media-wall ratio="282:282" v-model="form.avatar" :width="120" :height="120" @handleMediaData="validate()">
          <template #info>
            <div class="info"><i class="el-icon-info" />建议尺寸282*282</div>
          </template>
        </single-media-wall>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
  export default {
    name: "OptionCreateDialog",
    components: {SingleMediaWall},
    props: {
      index: Number,
      value: {
        type: Boolean,
        default: false
      },
      form: {
        type: Object
      },
      type: {
        Number
      }
    },
    data() {
      return {
        loading: false,
        saveLoading: false,
        rules: {
          name: [
            {required: true, message: '请填写标题', trigger: 'blur'}
          ],
          avatar: [
            {required: true, message: '请上传图片'}
          ]
        }
      }
    },
    computed: {
      dialogTitle() {
        return (this.index === -1 ? '新增' : '编辑') + '选项'
      }
    },
    methods: {
      validate() {
        this.$refs['optionForm'].validate()
      },
      close() {
        this.$emit('input', false)
        this.$refs['optionForm'].clearValidate()
      },
      confirm () {
        this.$refs['optionForm'].validate(valid => {
          if (valid) {
            this.$emit('confirm', this.form)
            this.close ()
          } else {
            return false
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
